<template>
  <div class="row">
    <div class="col-12">
      <h2 class="w-100 text-start">Edit Deal</h2>
    </div>
  </div>

  <Fieldset legend="Financial Info" :toggleable="true">
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Sale Price</label>
          <input type="text" class="form-control" disabled :value="Number(deal.sale_price).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">% To BR</label>
          <input type="text" class="form-control" disabled :value="Number(deal.br_percentage).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ To BR</label>
          <input type="text" class="form-control" disabled :value="Number(deal.br_money).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Ref Fee</label>
          <input type="text" class="form-control" disabled :value="totalRefMoney.toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">% Ref Fee</label>
          <input type="text" class="form-control" disabled :value="totalRefPercentage.toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">Ref Fee</label>
          <input type="text" class="form-control" disabled :value="Number(deal.ref_fee_total).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Left To Split 1</label>
          <input type="text" class="form-control" disabled :value="Number(deal.left_to_split_1).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">% To Team</label>
          <input type="text" class="form-control" v-model="deal.team_percentage" @change="recalculate" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ To Team</label>
          <input type="text" class="form-control" disabled :value="Number(deal.team_money).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Left To BR</label>
          <input type="text" class="form-control" disabled :value="Number(deal.left_to_br).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">% To Closing Cord</label>
          <input type="text" class="form-control" v-model="deal.closing_cord_percentage" @change="recalculate"/>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ To Closing Cord</label>
          <input type="text" class="form-control" disabled :value="Number(deal.closing_cord_money).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Left To Split 2</label>
          <input type="text" class="form-control" disabled :value="Number(deal.left_to_split_2).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">% Agent Split</label>
          <input type="text" class="form-control" v-model="deal.agent_split_percentage" @change="recalculate" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ To Agent</label>
          <input type="text" class="form-control" disabled :value="Number(deal.agent_split_money).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Assistants</label>
          <input type="text" class="form-control" disabled :value="totalAssistMoney.toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">% Assistants</label>
          <input type="text" class="form-control" disabled :value="totalAssistPercentage.toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">Assistants</label>
          <input type="text" class="form-control" disabled :value="Number(deal.assistants_fee_total).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Left To Split 3</label>
          <input type="text" class="form-control" disabled :value="Number(deal.left_to_split_3).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="" class="form-label">$ Head Of {{ deal.team?.name }}</label>
          <input type="text" class="form-control" disabled :value="Number(deal.head).toFixed(2)" />
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label for="" class="form-label">$ Agent Final</label>
          <input type="text" class="form-control" v-model="deal.agent_final" @change="recalculate" />
        </div>
      </div>
    </div>
  </Fieldset>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { useRoute } from 'vue-router'

import Fieldset from 'primevue/fieldset'

import DealService from '../../services/deal.service'
import Deal from '../../models/Deal'

export default {
  components: { Fieldset },
  setup() {
    const route = useRoute()
    const deal = ref(new Deal())
    const totalRefPercentage = ref(0)
    const totalRefMoney = ref(0)
    const totalAssistPercentage = ref(0)
    const totalAssistMoney = ref(0)

    onMounted(async () => {
      deal.value = await DealService.fetchDeal(route.params.id)
      deal.value.referrals.forEach((r) => {
        totalRefPercentage.value += Number(r.fee_percentage)
        totalRefMoney.value += Number(r.fee_money)
      })

      deal.value.assistants.forEach((a) => {
        totalAssistPercentage.value += Number(a.fee_percentage)
        totalAssistMoney.value += Number(a.fee_money)
      })
    })

    const recalculate = () => {
      const values = DealService.calculateDealValues({
        salePrice: deal.value.sale_price,
        brPercentage: deal.value.br_percentage,
        teamPercentage: deal.value.team_percentage,
        closingCordPercentage: deal.value.closing_cord_percentage,
        agentSplitPercentage: deal.value.agent_split_percentage,
        referrals: deal.value.referrals,
        assistants: deal.value.assistants,
      })
      const newDeal = { ...deal.value, ...values }
      deal.value = newDeal
      DealService.updateDeal(deal.value.id, {
        ...values,
        team_percentage: newDeal.team_percentage,
        agent_split_percentage: newDeal.agent_split_percentage,
        agent_final: newDeal.agent_final,
      }).catch(console.log)
    }

    return { deal, totalRefPercentage, totalRefMoney, totalAssistPercentage, totalAssistMoney, recalculate }
  },
}
</script>

<style></style>
